.app-container {
    margin: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.time_condition {
    text-align: left;
    margin-bottom: 30px;
}
.time_condition label {
    margin-left: 8px;
}
input::file-selector-button {
    color: dodgerblue;
    padding: 0.3em;
    border: thin solid grey;
    border-radius: 5px;
    cursor: pointer;
}